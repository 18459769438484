import toDash from 'plugins/string/to_dash';

function dashedKeys(object) {
  return Object.keys(object).reduce(
    (obj, key) => ({ ...obj, [toDash(key)]: object[key] }),
    {},
  );
}

function inlineCss(element, stylesObject = null, keepPrevious = true) {
  const e = element;
  let inlineStyles = e.style.cssText
    .replace(/^\s*|\s*$|(;)\s*|(:)\s*/g, '$1$2')
    .split(';')
    .reduce((obj, value) => {
      if (!value) {
        return obj;
      }

      const arr = value.split(':');

      return {
        ...obj,
        [arr[0]]: arr[1],
      };
    }, {});

  if (!stylesObject) {
    return inlineStyles;
  }

  if (keepPrevious) {
    inlineStyles = {
      ...inlineStyles,
      ...dashedKeys(stylesObject),
    };
  } else {
    inlineStyles = dashedKeys(stylesObject);
  }

  const newCssText = Object.keys(inlineStyles).reduce(
    (text, key) => `${text} ${key}: ${inlineStyles[key]};`,
    '',
  );

  e.style.cssText = newCssText;

  return inlineStyles;
}

export default inlineCss;
