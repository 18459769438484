import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';
import toElement from 'plugins/element/to_element';

function toggleDisplay(element, options = {}) {
  if (!isVariableDefinedNotNull(element)) {
    return;
  }

  const e = toElement(element);

  if (options.show) {
    e.style.display = isVariableDefinedNotNull(options.display) ? options.display : 'block';
    return;
  }

  e.style.display = 'none';
}

export default toggleDisplay;
