import toggleDisplay from 'plugins/element/toggle_display';

function hide(element, options = {}) {
  if (options.useHiddenAttr) {
    element.hidden = true;
    return;
  }

  toggleDisplay(element, { ...options, show: false });
}

export default hide;
