import anime from 'animejs';

import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';

function fadeIn(element, options = {}) {
  const e = element;
  let o = options;

  const opacity = isVariableDefinedNotNull(o.opacity) ? o.opacity : 1;
  const duration = isVariableDefinedNotNull(o.duration) ? o.duration : 400;
  const easing = isVariableDefinedNotNull(o.easing) ? o.easing : 'easeInOutQuad';
  const display = isVariableDefinedNotNull(o.display) ? o.display : 'block';
  const complete = isVariableDefinedNotNull(o.complete) ? o.complete : null;
  const begin = isVariableDefinedNotNull(o.begin) ? o.begin : null;

  o = Object.keys(o).reduce((acc, k) => {
    if (['display', 'complete', 'begin', 'useHiddenAttr'].indexOf(k) > -1) {
      return acc;
    }

    return {
      ...acc,
      [k]: o[k],
    };
  }, {});

  const properties = {
    ...o,
    opacity,
    duration,
    easing,
  };

  if (e.style.opacity === '') {
    e.style.opacity = 0;
  }

  if (isVariableDefinedNotNull(e.anime)) {
    e.anime.pause();
  }

  e.anime = anime({
    targets: e,
    ...properties,
    begin: anim => {
      if (options.useHiddenAttr) {
        e.hidden = false;
      } else {
        e.style.display = display;
      }

      if (begin) {
        begin(e, anim);
      }
    },
    complete: anim => {
      e.anime = undefined;

      if (complete) {
        complete(e, anim);
      }
    },
  });
}

export default fadeIn;
