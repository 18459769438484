import isNaN from 'plugins/number/is_nan';
import capitalizeFirstLetter from 'plugins/string/capitalize_first_letter';

function getOffset(direction, element, toElement = undefined) {
  const d = capitalizeFirstLetter(direction.toLowerCase());

  let e = element;
  let offset = 0;
  let keepGoing = !!toElement;

  do {
    if (keepGoing && toElement === e.offsetParent) {
      keepGoing = false;
    }

    if (!isNaN(e[`offset${d}`])) {
      offset += e[`offset${d}`];
    } else if (e.offsetParent) {
      if (d === 'Right') {
        offset += e.offsetParent.offsetWidth - e.offsetLeft - e.offsetWidth;
      } else if (d === 'Bottom') {
        offset += e.offsetParent.offsetHeight - e.offsetTop - e.offsetHeight;
      }
    }

    e = e.offsetParent;
  } while (keepGoing && e);

  return offset;
}

export default getOffset;
