function isElement(content) {
  return typeof HTMLElement === 'object'
    ? content instanceof HTMLElement || content instanceof HTMLCollection
    : content &&
        typeof content === 'object' &&
        content !== null &&
        content.nodeType === 1 &&
        typeof content.nodeName === 'string';
}

export default isElement;
