import toggleDisplay from 'plugins/element/toggle_display';

function show(element, options = {}) {
  if (options.useHiddenAttr) {
    element.hidden = false;
    return;
  }

  element.classList.remove('hidden');
  toggleDisplay(element, { ...options, show: true });
}

export default show;
